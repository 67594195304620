.bg-codemy
  background-image: linear-gradient(100deg, config('colors.pacific-darker'), config('colors.pacific-dark'))

button
  outline: none
  &:focus 
    outline: none

.font-codemy-sans
  font-family: 'Open Sans', config('fonts.sans')

.jwplayer
  @apply rounded-t

pre[class*="language-"], code[class*="language-"]
  display: block
  min-height: 1.5em

samp
  background-color: #cecece
  font-family: 'Courier New', Courier, monospace
  color: #555
  padding: 1px 2px
  border-radius: 2px